.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1rem;
  // padding: 2rem;
  padding-bottom: 4rem;

  @include respond-to(phone) {
    padding: 1rem;
    font-size: 0.9rem;
  }

  @at-root #{&}__row {
    @extend .row;
    width: 100%;
    padding: 2em 0;
    border-bottom: 1px solid $primary-light;
    flex-shrink: 0;

    @include respond-to(phone) {
      padding: 1em 0;
      flex-wrap: wrap;
    }

    &.disabled label {
      color: $gray-light;
    }

    p {
      margin-top: 0;
    }
  }

  @at-root #{&}__section {
    @extend .row;
    text-transform: uppercase;
    font-size: 1.2em;
    margin: 5em 0;
    margin-bottom: 1em;
    font-weight: normal;
    color: $primary;
    user-select: none;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;

    @include respond-to(phone) {
      margin-top: 3em;
    }

    mat-slide-toggle {
      margin-left: 1em;
    }

    &:first-child {
      margin-top: 0;

      @include respond-to(phone) {
        margin-top: 2em;
      }
    }

    @at-root #{&}__description {
      font-weight: lighter;
      font-size: 0.9em;
      line-height: 1.5em;
      margin-bottom: 1em;
      user-select: none;
    }
  }

  @at-root #{&}__label {
    @extend .row;
    width: 30%;
    flex-shrink: 0;
    text-transform: uppercase;
    color: $gray;
    font-size: 0.9em;
    font-weight: normal;
    letter-spacing: 0.15em;
    user-select: none;
    min-height: 3em;
    white-space: break-spaces;

    @include respond-to(phone) {
      width: 45%;

      &.wrap {
        width: 100% !important;
        margin-bottom: 0.5em;
      }
    }

    span {
      flex-grow: 1;
      overflow-wrap: break-word;
      min-width: 0;
    }

    img {
      width: 1.8em;
      margin-right: 1.5em;
    }

    .material-icons {
      margin-right: 1em;
      font-size: 1.6em;

      @include respond-to(phone) {
        margin-right: 0.8em;
      }
    }
  }

  @at-root #{&}__field {
    @extend .row;
    font-size: 1em;
    justify-content: flex-start;
    min-height: 3em;
    min-width: 50%;
    flex-grow: 1;
    font-weight: normal;
    color: $gray-dark;
    flex-wrap: wrap;

    .text-button {
      font-size: 1em;
      // min-height: 3rem;
    }

    .column {
      width: 100%;
    }

    @include respond-to(phone) {
      width: auto;
      flex-grow: 1;
    }

    textarea:not(:first-child),
    input:not(:first-child) {
      margin-left: 0.5em;
    }

    .icon-button {
      color: $gray;
    }

    textarea,
    input {
      flex-grow: 1;
      min-height: 3rem;
      font-size: 1em;
    }

    textarea:read-only,
    input:read-only {
      background-color: transparent;
      border-color: transparent;
      color: $gray-dark;
    }

    .mat-slide-toggle {
      height: 3rem;
    }

    mat-slider {
      width: 80%;
    }

    label {
      width: 20%;
      text-align: center;
      color: $primary;
      font-weight: bold;
      font-size: 1.1em;
    }

    .material-icons + span {
      padding-left: 1em;
    }

    .status {
      margin-left: -1.2rem;
    }

    a {
      text-transform: none;
    }
  }

  @at-root #{&}__action {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 3em;
    padding-left: 1em;
    min-width: 2.5rem;
    user-select: none;
    justify-content: flex-end;

    @include respond-to(phone) {
      width: 3rem;
      justify-content: flex-end;
      padding: 0;
    }

    .icon-button {
      font-size: 1.5em;

      @include respond-to(phone) {
        padding: 0;
      }
    }
  }

  @at-root #{&}__group {
    display: flex;
    flex-direction: column;
    padding: 1em;

    label {
      @extend .row;
      align-items: center;
      text-transform: uppercase;
      color: $gray-dark;
      font-size: 0.8em;
      margin-bottom: 0.8em;

      .material-icons {
        font-size: 1.5em;
        margin-right: 0.5em;
      }
    }
  }
}
