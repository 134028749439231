.chart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chart__header {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  color: $primary;
  flex-shrink: 0;
  min-width: 0;

  @include respond-to(phone) {
    padding: 0;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 1.2rem;

    .material-icons {
      margin-right: 1rem;
      font-size: 1.5rem;
    }

    span {
      text-transform: capitalize;
    }
  }

  .description {
    font-size: 1rem;
    color: $gray;
    padding-top: 0.5rem;
    padding-left: 2px;
  }
}

.chart__content {
  flex-grow: 1;
  min-width: 0;
  min-height: 0;
  width: 100%;
}

.chart__footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 2rem;
  flex-shrink: 0;

  .xlabel {
    font-size: 0.9rem;
    text-transform: capitalize;
    color: $primary;
  }
}
