app-sidebar {
  @include respond-to(phone) {
    display: none;
  }
}

.sidebar {
  @extend .column;
  background-color: $black;
  // background-color: white;
  // width: 20rem;
  width: 4rem;
  height: 100%;
  overflow-x: hidden;
  box-shadow: $box-shadow-dark;
  transition: width 0.2s;

  &.expand {
    width: 20rem;

    .sidebar__logo img {
      width: 3rem;
    }

    .sidebar__user {
      justify-content: space-between;
    }

    .hide-in-compact {
      display: flex;
    }
  }

  .hide-in-compact {
    display: none;
  }

  @at-root #{&}__logo {
    @extend .row;
    padding: 1.5rem 0;
    width: 100%;
    border-bottom: 1px solid $black-100;
    // border-bottom: 1px solid $primary-light;
    justify-content: center;
    height: 5rem;

    img {
      width: 1.5rem;
      transition: width 0.2s;
    }
  }

  @at-root #{&}__menu {
    flex-grow: 1;
    width: 100%;

    @at-root #{&}__item {
      @extend .row;
      width: 100%;
      color: $white;
      // color: $gray;
      // padding: 1.5rem 0;
      padding: 0;
      // padding-left: 3rem;
      font-size: 1rem;
      font-weight: lighter;
      text-transform: capitalize;
      justify-content: flex-start;
      border-radius: 0;
      overflow-x: hidden;
      height: 4rem;

      .icon {
        @extend .row;
        align-items: center;
        justify-content: center;
        width: 4rem;
        flex-shrink: 0;
      }

      span {
        margin: 0;
        flex-grow: 1;
        text-align: left;
      }

      .material-icons {
        font-size: 1.4em;
      }

      &:hover {
        background-color: $primary50;
      }

      &.active {
        color: white;
        background-color: $theme-color !important;
        box-shadow: $box-shadow;
      }
    }
  }

  @at-root #{&}__quick-actions {
    @extend .row;
    border-top: 1px solid $black-100;
    // border-top: 1px solid $primary-light;
    width: 100%;
    justify-content: flex-start;
    padding: 0.5rem 0;

    .icon-button {
      margin: 0 0.5rem;
      &:hover {
        background-color: $primary50;
      }
    }
  }

  .icon-button {
    font-size: 1.2rem;
    color: $white;
    // color: $gray;
    margin-right: 0;
  }

  @at-root #{&}__user {
    @extend .row;
    width: 100%;
    padding: 1.2rem 1rem;
    border-top: 1px solid $black-100;
    // border-top: 1px solid $primary-light;
    justify-content: center;

    user-select: none;

    @at-root #{&}__info {
      @extend .row;
      color: $white;
      // color: $black;
      font-size: 1rem;
      flex-grow: 1;
      justify-content: flex-start;
      padding: 0;

      .user-icon {
        width: 2rem;
        height: 2rem;
        font-size: 1rem;
      }

      .user-name {
        @extend .column;
        flex-grow: 1;
        justify-content: center;
        align-items: flex-start;
        height: 2rem;
        font-size: 0.9rem;

        > * {
          @extend .ellipsis;
        }

        .email {
          text-transform: none;
          margin: 0;
          margin-top: 2px;
          color: $white80;
          // color: $gray;
          font-size: 0.9em;
        }
      }
    }
  }

  @include respond-to(tablet) {
    width: 4rem;

    .sidebar__logo img {
      width: 1.5rem;
    }

    .sidebar__menu__item {
      padding: 1.5rem 0;
      justify-content: center;

      .material-icons {
        margin-right: 0;
      }

      span {
        display: none;
      }
    }

    .sidebar__user {
      justify-content: center;
    }

    .sidebar__collapse,
    .sidebar__user__info {
      display: none;
    }

    #btn-sign-out {
      margin: 0;
    }

    #btn-language {
      display: none;
    }
  }
}
