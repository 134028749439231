.mat-dialog-container {
  min-width: 35rem;
  max-height: 80vh !important;
  max-width: 80vw !important;

  @include respond-to(phone) {
    min-width: 0;
    border-radius: 6px;
  }
}

.dialog {
  display: flex;
  flex-direction: column;
  width: 35rem;
  max-width: 90vw;
  max-height: 90vh;
  padding: 2rem;

  @include respond-to(phone) {
    width: 100% !important;
  }

  @at-root #{&}__title {
    font-size: 1.4rem;
    font-weight: lighter;
    padding: 1rem 0;
    margin-bottom: 1rem;
    // text-transform: capitalize;

    @include respond-to(phone) {
      font-size: 1.2rem;
    }

    .highlight {
      text-transform: none;
      text-decoration: underline;
      font-style: italic;
      margin-right: 0.5rem;
    }

    .material-icons {
      font-size: 1.4rem;
      margin-right: 0.5rem;

      @include respond-to(phone) {
        font-size: 1.2rem;
      }
    }

    .warning {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.2rem;
      color: $error;
      padding-bottom: 1rem;

      @include respond-to(phone) {
        font-size: 1rem;
      }
    }
  }

  @at-root #{&}__action {
    justify-content: flex-end;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid $primary-light;
  }

  .form {
    padding: 0 0.5rem;

    .form__row:last-child {
      border-bottom: none;
    }

    .form__field {
      max-width: 100%;
      flex-grow: 1;
    }
  }
}
