.chatroom {
  @extend .column;
  flex-grow: 1;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: $white;

  app-loader {
    z-index: 0;
    position: absolute;
  }

  .empty-placeholder {
    font-size: 1rem;

    .wrapper {
      width: 20rem;
      height: 20rem;
    }
  }

  @at-root #{&}__content {
    @extend .column;
    @extend .scrollable;
    height: 100%;
    margin: 0;
    padding: 1rem 1rem 0 1rem;
    border-radius: 0;

    @include respond-to(phone) {
      padding: 1.5rem 1rem;
      height: auto;
    }
  }
}

.message__timestamp {
  @extend .row;
  @extend .no-select;

  font-size: 0.8rem;
  justify-content: flex-end;
  position: absolute;
  bottom: 0.9em;
  right: 0.6rem;
  background: transparent;
  color: $gray80;

  @at-root .right #{&} {
    color: $white80;
  }

  i.material-icons {
    font-size: 1.2em;
    margin-left: 0.5em;
  }

  img {
    margin-left: 0.5em;
    width: 1em;
    opacity: 0.6;
  }
}

.message {
  @extend .column;
  width: 100%;
  padding: 0;

  &.right {
    align-items: flex-end;
    text-align: right;
  }

  @at-root #{&}__date {
    @extend .row;
    width: 100%;
    justify-content: center;
    color: $gray;
    text-transform: uppercase;
    padding: 0.5rem;
  }

  @at-root #{&}__wrapper {
    @extend .column;
    max-width: 50rem;
    text-align: left;
    font-size: 0.8rem;
    font-weight: lighter;
    padding: 0;
    justify-content: center;
    align-items: flex-start;

    @include respond-to(phone) {
      max-width: 80vw;
    }

    @at-root .right #{&} {
      align-items: flex-end;
    }

    @at-root .not-delivered#{&} {
      opacity: 0.5;

      @at-root .dark-theme #{&} {
        opacity: 0.2;
        filter: brightness(2);
      }
    }
  }

  @at-root #{&}__text {
    display: block;
    position: relative;
    color: $font-color;
    background-color: $primary-100;
    padding: 0.8rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    word-break: break-word;
    font-weight: normal;
    width: fit-content;
    white-space: pre-line;
    margin-top: 0.5rem;

    @at-root .right #{&} {
      background-color: var(--themeColor, $black-100);
      background-image: $gradient-light;
      color: $white;
    }

    @include respond-to(phone) {
      font-size: 1.2rem;
    }

    &.revoked {
      color: $gray;
      font-style: oblique;
      opacity: 0.5;
    }
  }

  @at-root #{&}__attachment {
    @extend .column;
    @extend .no-select;

    @at-root .right #{&} {
      justify-content: flex-end;
    }

    @at-root #{&}__item {
      @extend .column;
      font-size: 1rem;
      width: 20rem;
      height: auto;
      max-height: 20rem;
      border-radius: 0.5rem;
      padding: 0.3rem;
      overflow: hidden;
      background-color: $primary-100;
      margin-top: 0.5rem;

      @at-root .right #{&} {
        justify-content: flex-end;
        background-color: var(--themeColor, $black-100);
        background-image: $gradient-light;
      }

      @include respond-to(phone) {
        width: 50vw;
        max-height: 50vw;
      }

      a {
        padding: 0;
        width: 100%;
        border-radius: 0.5rem;
        background-color: transparent;
      }

      img,
      video {
        width: 100%;
        background-color: $white;
        padding: 0;
      }

      audio {
        width: 100%;
        margin: 0.5em 0.2em 0 0.2em;
        padding: 0;
      }

      .file {
        @extend .row;
        width: 100%;
        font-size: 1rem;
        padding: 0.5em;
        justify-content: flex-start;
        background-color: $primary-light;
        font-weight: normal;

        @at-root .right #{&} {
          background-color: transparent;
          background-image: $dark;
        }

        .material-icons {
          color: $primary-100;
          font-size: 2.5em;

          @at-root .right #{&} {
            color: $white;
          }
        }

        .filename {
          @extend .row;
          @extend .ellipsis;
          color: $font-color;
          padding: 0 0.5em;
          flex-grow: 1;
          text-transform: none;
          text-align: left;

          @at-root .right #{&} {
            color: $white;
          }
        }
      }
    }

    .message__timestamp {
      position: relative;
      width: 100%;
      opacity: 1;
      padding: 0.6em;
      padding-bottom: 0.2em;
      padding-right: 0;
      bottom: 0;
      right: 0;
      justify-content: space-between;
      align-items: flex-end;

      .info {
        @extend .row;
        @extend .ellipsis;
        max-width: 60%;
        color: $font-color;
        font-size: 0.8rem;

        @at-root .right #{&} {
          color: $white;
        }
      }

      .time {
        @extend .row;
        i.material-icons {
          font-size: 1.2em;
          margin-left: 0.5em;

          @at-root .right #{&} {
            color: $white80;
          }
        }

        img {
          margin-left: 0.5em;
          width: 1em;
          opacity: 0.6;
          background-color: transparent;
        }
      }
    }
  }

  @at-root #{&}__remark {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    padding-bottom: 0;
    color: $gray;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.2rem;

    @at-root .right #{&} {
      align-items: flex-end;
    }
  }

  @at-root #{&}__action {
    padding: 0 0.8rem;
    display: flex;
    flex-direction: row;

    @at-root .right #{&} {
      align-items: flex-end;
      margin-left: 0;
      margin-right: 5rem;
    }

    .text-button {
      color: $gray;
      font-size: 0.8rem;
      font-style: italic;
      text-transform: capitalize;
      max-width: 40rem;
      font-weight: lighter;
      padding: 0.5rem;
      min-height: 0;

      &:hover {
        background-color: $primary-light;
      }

      &.btn-dismiss:hover {
        background-color: $error-light;
      }
    }
  }

  @at-root #{&}__header {
    @extend .row;
    align-items: center;
    font-size: 0.8em;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: $primary;

    a {
      padding: 0;
      font-size: 1em;
      text-transform: none;
      color: $primary;
    }

    img {
      height: 1em;
      margin-left: 0.4em;
    }
  }
}

.quick-replies {
  @extend .row;
  width: 100%;
  justify-content: flex-end;
  padding: 1rem 0.7rem;

  .quick-reply {
    @extend .row;
    @extend .ellipsis;
    max-width: 10rem;
    padding: 0.8rem;
    background-color: transparent;
    border: 1px solid $primary;
    border-radius: 5px;
    font-size: 1rem;
    text-transform: none;
    color: $primary;
    min-height: 0;
  }

  .quick-reply:hover {
    background-color: $primary-light;
  }
}

.chatroom-preview {
  @extend .column;
  flex-shrink: 0;
  width: 22%;
  min-width: 30rem;
  max-width: 35rem;
  margin-left: 1rem;
  height: 100%;
  position: relative;
  box-shadow: $box-shadow;
  background-color: $white;
  align-self: flex-start;

  @include respond-to(phone) {
    display: none;
  }

  @include respond-to(tablet) {
    width: 24rem;
  }

  .header {
    @extend .row;
    justify-content: space-between;
    background-color: $theme-color;
    box-shadow: $box-shadow;
    flex-shrink: 0;
    padding: 1rem;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    z-index: 1;

    label {
      color: $white;
      font-size: 1.2rem;
    }
  }

  .actions {
    @extend .row;
    justify-content: flex-end;

    .icon-button {
      font-size: 1.2rem;
      color: $white;
    }
  }

  app-chatroom {
    flex-grow: 1;
    position: relative;
  }

  .mat-tab-body-content app-chatroom {
    position: initial;
  }
}
