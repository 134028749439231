input,
textarea {
  outline: none;
  font-family: Helvetica, "Roboto", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  border-radius: $border-radius;
  border: 1px solid transparent;
  padding: 0.5rem;
  background-color: $gray-light;
  // line-height: 2rem;

  &.invalid {
    border-color: $error;
    // color: $error;
  }

  &:focus {
    border-color: $primary;
  }

  &:read-only {
    cursor: initial;
    background-color: transparent;
    border-color: transparent;
  }

  @include respond-to(phone) {
    font-size: 1.2rem;
  }
}

input[type="file"] {
  display: none;
}

img {
  user-select: none;
}

p {
  word-break: break-word;
  white-space: break-spaces;
}

select {
  // line-height: 2rem;
  cursor: pointer;
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  box-sizing: content-box;
  resize: none;
  width: 100%;
}

input.colorpicker {
  max-width: 3.5rem;
  cursor: pointer;
}

.color-picker {
  border: none !important;
  box-shadow: $box-shadow-dark;
  border-radius: $border-radius;

  .saturation-lightness {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
}

::placeholder {
  font-style: italic;
  color: $gray !important;
  font-weight: lighter;
}

label {
  user-select: none;
  // line-height: 2rem;
}

.bold {
  font-weight: bolder;
}

.light {
  font-weight: lighter;
}

.monospace {
  font-family: monospace;
}

.no-transform {
  text-transform: none;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.empty-placeholder {
  @extend .column;
  // height: 100%;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  user-select: none;
  background-color: transparent;

  &.cover {
    z-index: 100;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .material-icons {
    color: $gray;
    font-size: 4em;
  }

  .text-button {
    @extend .gradient-dark;
    background-color: $gray;
    font-size: 1.1rem;
    padding: 1rem 3rem;
    color: $white;
  }

  .wrapper {
    @extend .column;
    justify-content: center;
    align-items: center;
    width: 25rem;
    height: 25rem;
    background-color: $gray-light;
    border-radius: 50%;
    color: $gray;
    padding: 2rem;
    white-space: pre;

    @include respond-to(phone) {
      width: 80vw;
      height: 80vw;
    }
  }

  div {
    @extend .row;
    max-width: 100%;
    margin: 1rem 0;
    padding: 0.5rem;
    text-align: center;
    line-height: 1.5em;
  }
}

.error-message {
  color: $error;
  font-size: 0.8rem;
  font-style: italic;
  padding: 0.2rem;

  &::before {
    content: "* ";
  }
}

.user-icon {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 1.8rem;
  margin-right: 1rem;
  text-transform: uppercase;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  user-select: none;
  background-image: url("../assets/img/profile.jpg");
}

.end-indicator {
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: lighter;
  text-align: center;
  padding: 2rem;
  color: $gray;
  flex-shrink: 0;
  user-select: none;
  grid-column: 1/-1;

  &::before {
    content: "- ";
  }

  &::after {
    content: " -";
  }
}

.samples {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem 0;

  @at-root #{&}__item {
    display: flex;
    flex-direction: row;
    background-color: $gray-light;
    border-radius: $border-radius;
    padding: 0.2rem 1rem;
    align-items: center;
    margin: 0 1rem 1rem 0;
    min-height: 2.5rem;

    a {
      padding: 0;

      .material-icons {
        margin-left: 0.5rem;
        font-size: 1.2rem;
      }
    }
  }
}

.tags {
  @extend .row;
  flex-wrap: wrap;
  font-size: 1rem;
  padding: 0;

  .mat-menu-content & {
    width: 25rem;
    padding: 0 0.5rem;
  }

  .filter & {
    flex-wrap: nowrap;
  }
}

.tags__item {
  @extend .row;
  background-color: $primary;
  border-radius: 8rem;
  padding: 0.5rem 1rem;
  align-items: flex-end;
  margin: 0 0.5rem 0.5rem 0;
  color: $white;
  min-height: 0;
  line-height: auto;
  text-transform: none;

  .filter & {
    margin-bottom: 0;
    padding: 0.4em 0.8em;
    font-size: 0.9rem;
  }

  a {
    padding: 0;
    color: $white50;
    margin-right: -0.5rem;

    &:hover {
      color: $error;
    }

    .material-icons {
      margin-left: 0.5rem;
      font-size: 1.2rem;
    }
  }
}

.text-button.add-tag {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
  min-height: 0;
  background-color: $primary-light;
  color: $primary-200;
  width: fit-content;
  border-radius: 8rem;
}

.tags-wrapper {
  @extend .row;
  flex-wrap: wrap;
  width: 16vw;
  min-width: 22rem;
  padding: 0.5rem 1rem 0 1rem;
}

.attachment__item {
  @extend .row;
  padding: 0 0.5rem;
  justify-content: space-between;

  a {
    @extend .ellipsis;
    color: $primary;
    max-width: 12rem;
    font-weight: lighter;
    text-transform: none;
    justify-content: flex-start;
  }

  &:hover {
    background-color: $primary-light;
  }
}

.status {
  @extend .row;
  justify-content: flex-start;
  min-width: 3rem;
  height: 3rem;

  span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $gray;
    background-image: radial-gradient(rgba(255, 255, 255, 0.3), transparent);
    cursor: pointer;
    padding: 0 !important;
    margin-left: calc(1.5rem - 4px);
  }

  label {
    @extend .ellipsis;
    margin-left: 1rem;

    @include respond-to(phone) {
      display: none;
    }
  }

  &.success span {
    background-color: $success;
  }

  &.warn span {
    background-color: $warn;
  }

  &.error span {
    background-color: $error;
  }
}

.selected-indicator {
  position: absolute;
  top: 2px;
  right: 2px;

  .material-icons {
    font-size: 1.8rem;
    color: $primary;
  }
}

.beta {
  position: relative;
  &::after {
    background-color: $warn;
    content: "beta";
    border-radius: 1rem;
    font-size: 0.7rem;
    padding: 2px 5px;
    color: #ffffff;
    font-weight: lighter;
    text-transform: uppercase;
    text-align: center;
    margin-left: 0.2rem;
    position: absolute;
    top: 0;
  }
}

.input-wrapper {
  @extend .column;
  width: 100%;

  small {
    padding: 0.5em;
  }
}

.vertical-resize-handle {
  height: 100%;
  width: 2px;
  border: none;
  border-right: 2px solid $gray-light;
  cursor: col-resize;

  &:hover {
    border-color: $primary;
  }
}

.notes {
  @extend .column;
  width: 100%;

  @at-root #{&}__item {
    @extend .row;
    width: 100%;
    border-left: 5px solid $primary;
    background-color: $white;
    padding: 0.5rem;
    padding-left: 1rem;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    margin-bottom: 0.5rem;

    span {
      flex-grow: 1;
    }

    a {
      align-self: flex-start;
      padding: 0 0.5rem;
      width: 2.5rem;
      color: $gray50;

      &:hover {
        color: $error;
      }
    }
  }
}

textarea.add-note {
  width: auto;
  margin-top: 0.5rem;
}

.divider {
  width: 100%;
  border-bottom: 1px solid $gray-light;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;

  &.verticle {
    width: auto;
    height: 100%;
    border-bottom: none;
    border-right: 1px solid $gray-light;
    padding: 0;
    margin: 0;
    padding-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.remark,
.error-message {
  @extend .row;
  font-size: 0.8em;
  padding: 0.5em 1em;
  font-weight: lighter;
  font-style: italic;
  // white-space: pre;
  line-height: 1.5em;
}

.remark::before {
  content: "* ";
}

.remark {
  color: $gray;
}

.error-message::before {
  content: "⚠️ ";
}

.error-message {
  color: $error;
}

img {
  position: relative;
}

img[alt]:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  color: $font-color;
  text-transform: none;
  line-height: 1.8;
  font-size: 0.8em;
  text-align: center;
  content: attr(alt);
}

.select-wrapper {
  @extend .row;
  flex-wrap: wrap;
}

.select-item {
  @extend .row;
  border: 1px solid $primary-light;
  border-radius: $border-radius;
  height: 5rem;
  width: 17rem;
  justify-content: flex-start;
  padding: 1rem;
  margin: 1rem 1rem 0 0;
  cursor: pointer;

  &:hover,
  &.selected {
    border-color: $primary;
    box-shadow: $box-shadow;
  }

  img {
    width: 3rem;
  }

  .material-icons {
    font-size: 3rem;
    color: $primary;
  }

  h2 {
    @extend .ellipsis;
    font-size: 1rem;
    margin-left: 1em;
    user-select: none;
  }
}
