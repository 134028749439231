.page {
  background-color: $white;
  // background-color: $primary-light;
  padding: 0;
  flex-grow: 1;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  @include respond-to(phone) {
    background-color: white;
    padding: 0 0 4rem 0;
  }

  app-loader {
    z-index: 1;
  }

  @at-root #{&}__container {
    @extend .column;

    width: 100%;
    height: 100%;
    padding: 0;
    margin: auto;
    max-width: 1140px;
    position: relative;
  }

  @at-root #{&}__header {
    @extend .row;
    background-color: $primary;
    // background-color: $theme-color;
    background-image: $gradient-dark;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    z-index: 2;
    color: $white;
    // color: $white;
    font-size: 1.6rem;
    letter-spacing: 0.2rem;
    padding: 0;
    // padding: 0 2rem;
    margin: 0;
    font-weight: normal;
    // text-transform: uppercase;
    flex-shrink: 0;
    user-select: none;
    border-radius: 0;
    height: 5rem;
    // min-height: 6rem;
    // border-bottom: 1px solid $primary-100;

    @include respond-to(phone) {
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
      font-size: 1.5rem;
      width: 100vw;
      padding: 2rem;

      height: auto;
      background-color: $primary;
      background-image: $gradient-dark;
      color: white;
      box-shadow: $box-shadow-dark;
      border: none;
      border-radius: 0;

      h1 {
        margin: 0;
        text-transform: capitalize;
      }
    }

    @include respond-to(tablet) {
      padding: 2rem;
    }

    // @at-root #{&}__title {
    //   @extend .row;
    //   display: none;
    //   padding: 0;
    //   height: 100%;
    //   flex-shrink: 0;

    //   @include respond-to(phone) {
    //     height: auto;
    //   }

    //   .material-icons {
    //     margin-right: 1rem;
    //     font-size: 1.8rem;

    //     @include respond-to(phone) {
    //       font-size: 2rem;
    //     }
    //   }

    //   &.column {
    //     flex-direction: column;
    //     align-items: flex-start;
    //     flex-grow: 1;
    //     justify-content: center;

    //     input {
    //       background-color: transparent !important;
    //       color: $white;
    //       font-size: 1.6rem;
    //       line-height: 1.6rem;
    //       border-bottom: 1px solid transparent;
    //       width: 45%;
    //       padding: 0.2rem;
    //     }

    //     input:focus:not(:read-only) {
    //       border-color: $white;
    //     }

    //     ::placeholder {
    //       color: $white;
    //       font-weight: normal;
    //       font-size: 1.6rem;
    //     }
    //   }
    // }

    @at-root #{&}__back {
      @extend .row;
      justify-content: flex-start;
      padding: 0;
      color: $primary-light;
      font-size: 0.9rem;
      font-weight: normal;
      margin-bottom: 0.3em;
      margin-left: 0.4em;

      .material-icons {
        font-size: 0.8em;
        margin-right: 0;
      }
    }

    @at-root #{&}__tab {
      @extend .row;
      flex-grow: 1;
      // margin-left: 3rem;
      height: 100%;

      @include respond-to(phone) {
        overflow-x: auto;
        padding: 0.2rem;
        padding-top: 1.5rem;
        width: 100%;
        margin-left: 0;
      }

      a {
        font-size: 1.2rem;
        padding: 0;
        margin: 0 3rem 0 0;
        text-transform: capitalize;
        border-radius: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: $white;
        // color: white;
        // min-width: 8rem;
        height: 100%;
        font-weight: bold;
        border-bottom: 5px solid transparent;
        border-image-slice: 1;

        @include respond-to(phone) {
          max-width: 8rem;
          font-size: 1.2rem;
          min-width: 0;
        }

        &.selected {
          border-image-source: $gradient-theme;
          // background-image: linear-gradient(
          //   0,
          //   rgba(255, 255, 255, 0.3),
          //   rgba(255, 255, 255, 0.3)
          // );
        }

        span {
          @extend .ellipsis;
          margin-left: 0;
          flex-grow: 1;

          @include respond-to(phone) {
            display: none;
          }

          &:only-child {
            margin-left: 0;

            @include respond-to(phone) {
              display: block;
            }
          }
        }

        .material-icons {
          display: none;
          font-size: 1.2em;

          @include respond-to(phone) {
            font-size: 1.7em;
          }
        }

        img {
          height: 1.2em;
          margin-right: 0.5em;

          @include respond-to(phone) {
            height: 1.5em;
          }
        }

        .separator {
          &::after {
            content: "/";
          }
          padding: 0 0.5em;
        }
      }
    }
  }

  @at-root #{&}__toolbar {
    @extend .row;
    margin: 0;
    padding: 1.5rem 1rem 0.5rem 1rem;
    background-color: $white;
    width: 100%;

    button {
      margin-right: 1rem;
    }

    // @include respond-to(tablet) {
    //   padding: 0.8rem 2rem;
    // }

    // button {
    //   color: $white;
    //   font-size: 1.1rem;

    //   &:hover {
    //     background-color: $black-light !important;
    //   }
    // }

    // .text-button {
    //   background-color: transparent;
    //   box-shadow: none;

    //   @include respond-to(phone) {
    //     font-size: 1.3rem;
    //   }
    // }

    // .status {
    //   padding: 0 1rem;
    // }
  }

  @at-root #{&}__content {
    @extend .scrollable;
    background-color: $white;
    // margin: 1rem;
    margin: 0;
    flex-grow: 1;
    // overflow: hidden;
    height: 100%;
    position: relative;
    min-height: 0;
    // padding: 1rem;

    @include respond-to(phone) {
      margin: 0;
      width: 100%;
    }

    @at-root #{&}__title {
      @extend .row;
      margin-bottom: 1rem;
      margin-top: 4rem;

      h1 {
        font-size: 1.8rem;
        color: $primary;
        font-weight: bold;
        text-transform: uppercase;
      }

      .actions {
        @extend .row;
        margin-left: auto;
      }
    }

    @at-root #{&}__title {
      @extend .row;
      margin-bottom: 1rem;
      margin-top: 4rem;

      h1 {
        font-size: 1.8rem;
        color: $primary;
        font-weight: bold;
        text-transform: uppercase;
      }

      .actions {
        @extend .row;
        margin-left: auto;
      }
    }
  }

  @at-root #{&}__nav {
    @extend .row;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $primary-light;

    a {
      font-size: 1.4rem;
      font-weight: bold;
      color: $font-color;
      border-radius: 5px;
      opacity: 0.6;

      &.selected {
        background-color: $primary-light;
        opacity: 1;
      }

      img {
        height: 1.5em;
      }
    }
  }
}

.page.darkmode {
  background-color: $black-dark;
}
