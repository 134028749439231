.tasks {
  @extend .scrollable;
  padding: 1rem;
  padding-right: 0.5rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  justify-content: center;

  @include respond-to(phone) {
    padding: 2rem 2rem;
  }
}

.task {
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 0.2rem;
  min-width: 24rem;
  background-color: $white;

  @at-root #{&}__title {
    font-size: 1.1rem;
    padding: 1rem;
    text-align: center;
    background-color: $primary;
    color: white;
    white-space: pre-line;

    &.builtin {
      background-color: $black;
    }

    label {
      display: block;
      text-align: center;
      margin-bottom: 0.5rem;
      font-weight: normal;
    }

    span {
      font-weight: lighter;
      font-size: 0.8rem;
    }
  }

  @at-root #{&}__option {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    padding-top: 1.5rem;

    @at-root #{&}__item {
      text-transform: none;
      color: $font-color;
      border-color: $primary-light;
      font-weight: normal;
      width: 100%;
      margin: 0;
      margin-bottom: 0.5rem;

      .query {
        @extend .ellipsis;
        display: inline-block;
        max-width: 80%;
      }

      .material-icons {
        font-size: 1em;
        margin-left: 0.5rem;
        color: $gray;
      }

      @at-root .right #{&} {
        margin-left: 0 !important;
      }

      &:hover,
      &.checked {
        background-color: $success;
        color: $font-color;
        border-color: $success;
      }

      &.warn:hover,
      &.warn.checked {
        background-color: $warn;
        color: $font-color !important;
        border-color: $warn;
      }
    }

    .more {
      color: $gray;
      text-transform: lowercase;
      font-size: 0.8rem;
    }
  }

  @at-root #{&}__action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    padding-top: 0;
    width: 100%;

    app-loader-button {
      margin-right: 0.5rem;
    }

    app-loader-button:last-of-type {
      flex-grow: 1;
      margin-right: 0;
    }
  }
}
