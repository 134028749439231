button,
a {
  @extend .ellipsis;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  cursor: pointer;
  outline: none;
  border: none;
  user-select: none;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  text-transform: capitalize;
  padding: 0.5em 1em;
  border-radius: $border-radius;
  text-align: center;
  justify-content: center;
  background-color: transparent;
  color: $font-color;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    opacity: 0.9;
  }

  span {
    margin-left: 0.6em;
    padding: 0;
    @include respond-to(phone) {
      margin-left: 0.2em;
    }
  }

  span:first-child {
    padding-left: 0;
    margin-left: 0;
  }

  img,
  .material-icons {
    user-select: none;
  }

  &.mat-menu-item,
  .mat-menu-item & {
    @extend .row;
    box-shadow: none;
    border-radius: 0;
    justify-content: flex-start;
    background-color: white;
    font-size: 1rem;
    margin: 0;
    box-shadow: none;

    .material-icons {
      font-size: 1.1em;
    }

    span {
      @extend .ellipsis;
    }

    img {
      margin-right: 0.5em;
    }

    &:hover:not([disabled]) {
      background-color: $primary-light;
    }

    &.selected,
    &.selected:hover:not([disabled]) {
      background-color: $primary;
      color: $white !important;
    }
  }
}

button {
  margin-right: 0.5rem;
  min-width: 0;
  min-height: 2.5rem;
  line-height: 1;
  border-radius: 2rem;

  // :hover:not(:disabled) {
  //   background-color: $primary-light;
  // }

  &:last-child {
    margin-right: 0;
  }

  .material-icons {
    margin: 0;
    font-size: 1.2em;
  }

  img {
    margin: 0;
    height: 1em;
  }

  app-loader {
    z-index: 0 !important;
    position: absolute;
    padding: 0 !important;
    margin: auto;
  }

  &.round {
    border-radius: 2rem;
  }
}

// .outline-button,
// .text-button {
//   padding: 0.5rem 1rem;
// }

.outline-button {
  border: 1px solid;
  background-color: transparent;
  box-shadow: none;
}

button.language {
  text-transform: uppercase;
  padding: 1rem;
}

.radio-button-group {
  @extend .row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.radio-button {
  input[type="radio"] {
    display: none;
    // position: absolute;
    // opacity: 0;
    // cursor: pointer;
    // height: 0;
    // width: 0;
  }

  label {
    color: $primary;
    font-weight: lighter;
    font-size: 1.5em;
    text-align: center;
    display: flex;
    flex-direction: row;
    height: 2.5em;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 5em;
    margin: 0 0.5em;
    margin-bottom: 0.5em;
    min-width: 10em;
    cursor: pointer;

    &.checked {
      border: 2px solid $primary;
      background-color: $primary;
      color: $white;
      box-shadow: $box-shadow;
      font-weight: bold;
    }
  }
}

.text-button {
  @include respond-to(phone) {
    padding: 0.5rem;
  }
}

.text-button span {
  @include respond-to(phone) {
    display: none;
  }

  &.keep,
  &:only-child {
    @include respond-to(phone) {
      display: block;
    }
  }
}

.icon-button {
  border-radius: 50%;
  width: fit-content;
  min-width: 2.5rem;
  padding: 0.5em;

  &:disabled {
    color: $gray !important;
  }

  // &:hover:not(:disabled) {
  //   background-color: rgba(255, 255, 255, 0.5);
  // }
}

.text-button.ig-button {
  background-image: $instagram_gradient;
  color: $white;
  font-weight: bold;
  margin: 0 1em;

  &:disabled {
    background-image: none;
    background-color: $gray;
  }
}
