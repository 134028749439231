.primary {
  color: $primary;
}

.secondary {
  font-weight: bold;
}

.primary-light {
  color: $primary-light;
}

.bg-primary {
  color: $white;
  background-color: $primary;

  &.secondary {
    color: $primary;
    background-color: $primary-light;
  }
}

.border-primary {
  color: $primary;
  border-color: $primary;
}

.border-primary-light {
  color: $primary-light;
  border-color: $primary-light;
}

.success {
  color: $success;
}

.bg-success {
  color: $white;
  background-color: $success;

  &.secondary {
    color: $success;
    background-color: $success-light;
  }
}

.border-success {
  color: $success;
  border-color: $success;
}

.black {
  color: $black;
}

.bg-black {
  color: $white;
  background-color: $black;
}

.border-black {
  color: $black;
  border-color: $black;
}

.white {
  color: $white;
}

.bg-white {
  background-color: $white;
}

.gray {
  color: $gray;
}

.gray-light {
  color: $gray-light;
}

.bg-gray {
  color: $white;
  background-color: $gray;
}

.border-gray {
  color: $gray;
  border-color: $gray;
}

.purple {
  color: $purple;
}

.bg-purple {
  color: $white;
  background-color: $purple;
}

.border-purple {
  color: $purple;
  border-color: $purple;
}

.warn {
  color: $warn;
}

.bg-warn {
  color: $gray-dark;
  background-color: $warn;

  &.secondary {
    color: $warn;
    background-color: $warn-light;
  }
}

.border-warn {
  color: $warn;
  border-color: $warn;
}

.error {
  color: $error;
}

.bg-error {
  color: $white;
  background-color: $error;

  &.secondary {
    color: $error;
    background-color: $error-light;
  }
}

.border-error {
  color: $error;
  border-color: $error;
}

.bg-light {
  background-color: transparent;
  background-image: $light;
}

.bg-dark {
  background-color: transparent;
  background-image: $dark;
}

.gradient-light {
  background-image: $gradient-light;
}

.gradient-dark {
  background-image: $gradient-dark;
}

.gradient-theme {
  background-image: $gradient-theme;
}

.flat {
  box-shadow: none;
}

.flat:hover {
  background-color: $primary-light;
  opacity: 1;
}

.bg-primary,
.bg-error,
.bg-warn,
.bg-success,
.bg-black,
.bg-purple {
  &:disabled {
    background-color: $gray;
    color: $white;
  }
}

.ig-text {
  font-weight: bold;
  background-color: $instagram-purple;
  background-image: $instagram-gradient;
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.fb-text {
  color: $facebook-blue;
  font-weight: bold;
}
