@import "variables";
@import "responsive";
@import "layout";
@import "elements";
@import "color";

@import "button";
@import "dialog";
@import "card";
@import "page";
@import "auth";
@import "setting";
@import "knowledge";
@import "sidebar";
@import "form";
@import "task";
@import "chart";
@import "chatroom";
@import "chatform";
@import "chatflow";
@import "onboarding";
@import "privacy";
@import "dark";

html,
body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 12px;
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  margin: 0;
  color: $font-color;
  position: relative;
  letter-spacing: 0.1rem;
  overflow: hidden;
  background-color: $white;
  // background-color: $primary-light;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;

  @include respond-to(phone) {
    font-size: 11px;
    // background-color: $black-dark;
  }

  @include respond-to(tablet) {
    font-size: 11px;
  }
}

* {
  box-sizing: border-box;
  letter-spacing: 0.1rem;
  outline: none;
}

::-webkit-scrollbar {
  width: 5px;

  @include respond-to(phone) {
    width: 0;
    height: 0;
  }
}

::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: transparent;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: $gray-light;
  outline: none;
  border-radius: 2px;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.mat-menu-panel {
  margin: 0.5rem 0;
}

.mat-simple-snackbar {
  justify-content: center !important;
}

.mat-drawer-side {
  border: none;
}

.mat-drawer-container,
.mat-drawer-content {
  background-color: $primary-light;
  height: 100%;
}

.mat-dialog-container {
  padding: 0 !important;
}

.mat-tab-label-content {
  text-transform: uppercase !important;
}

.mat-tab-header,
.mat-tab-nav-bar {
  border-bottom: none !important;
}

.mat-tab-labels {
  border-bottom: 1px solid $gray-light;
}

.mat-tab-link,
.mat-tab-label {
  height: 3rem !important;
  min-width: 4rem !important;
  padding: 1rem 2rem !important;
  opacity: 0.7 !important;
  font-weight: lighter !important;
  font-size: 1rem !important;

  @include respond-to(phone) {
    font-size: 1.2rem !important;
  }
}

.mat-tab-label-active {
  color: $primary !important;
  opacity: 1 !important;
  font-weight: bold !important;

  .mat-tab-label-content {
    font-weight: bold !important;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $primary !important;
}

.mat-progress-bar-buffer {
  background-color: $primary-light !important;
}

.mat-progress-bar-fill::after {
  background-color: $primary !important;
}

.mat-menu-panel {
  min-height: 0 !important;
  min-width: 0 !important;
}

.no-header > .mat-tab-header {
  display: none !important;
}

.mat-tab-label .mat-tab-label-content {
  user-select: none;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $success;
  margin-left: 0px;
}

.mat-slide-toggle.mat-checked .mat-ripple-element,
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $success-light;
}

.mat-slide-toggle-thumb-container,
.mat-slide-toggle-thumb {
  height: 12px !important;
  width: 12px !important;
  margin-left: 2px;
  margin-top: 2px;
}

.mat-slide-toggle-bar {
  width: 34px !important;
  height: 18px !important;
  background-color: $primary-light;
  border-radius: 1rem !important;
}

.mat-slide-toggle-thumb-container {
  top: -1px !important;
}

.mat-slide-toggle .mat-slide-toggle-ripple {
  display: none !important;
}

.mat-slide-toggle-content {
  text-transform: capitalize;
  font-weight: lighter;
}

.mat-slide-toggle-thumb-container,
.mat-slide-toggle-bar,
.mat-slide-toggle-thumb {
  transition: none !important;
  transition-delay: 0ms !important;
  cursor: pointer;
}

.mat-slide-toggle.mat-disabled .mat-slide-toggle-bar,
.mat-slide-toggle.mat-disabled .mat-slide-toggle-thumb,
.mat-slide-toggle.mat-disabled .mat-slide-toggle-label,
.mat-slide-toggle.mat-disabled .mat-slide-toggle-thumb-container {
  cursor: not-allowed !important;
}

.mat-menu-item {
  min-height: 2.5rem !important;
  height: auto !important;
  line-height: 2.5rem !important;
}

.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
  background-color: $primary !important;
}

.mat-slider-track-background {
  background-color: $primary-light !important;
}

.cdk-global-scrollblock {
  overflow-y: auto !important;
}

.mat-accordion {
  width: 100%;
}

.mat-slider-disabled {
  .mat-slider-thumb,
  .mat-slider-thumb-label,
  .mat-slider-track-fill {
    background-color: $gray-light !important;
  }

  .mat-slider-track-background {
    background-color: $gray-light !important;
  }
}

.mat-slider-thumb-label-text {
  padding-left: 0 !important;
  font-size: 0.8rem !important;
}

.mat-paginator,
.mat-table {
  width: 100%;
}

.mat-paginator {
  background-color: transparent;
}

.mat-header-cell {
  text-transform: capitalize;
  font-weight: bold;
  color: $black;

  @include respond-to(phone) {
    font-size: 1rem;
  }
}

.mat-cell,
.mat-footer-cell {
  font-size: 1rem;
  color: $gray-dark;
}

.mat-header-cell,
.mat-cell,
.mat-footer-cell {
  @extend .ellipsis;
  padding: 0 1rem !important;
  display: table-cell;

  @include respond-to(phone) {
    max-width: 7rem;
  }
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type,
th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  @include respond-to(phone) {
    padding: 0 !important;
  }
}

.mat-sort-header-button span {
  margin-left: 0;
}

.ngx-charts {
  .tooltip-anchor {
    fill: $primary-light !important;
  }

  text {
    fill: $gray !important;
    font-size: 0.9rem !important;
  }
}

.chart-legend .legend-labels {
  background-color: transparent !important;
  text-align: center !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: transparent !important;

  .mat-expansion-panel-spacing {
    margin: 0 !important;
  }

  &.chatform-items > .drag-handle {
    top: 1.7rem;
  }
}

.cdk-drag-placeholder {
  opacity: 0.5;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.draggable-list.cdk-drop-list-dragging
  .draggable-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-checkbox {
  color: $gray;
  opacity: 0.5;
  // text-transform: capitalize;
}

.mat-checkbox-checked {
  color: $primary;
  opacity: 1;

  .mat-checkbox-background {
    background-color: $primary !important;
  }
}

.mat-table {
  .material-icons {
    color: $gray;
    font-size: 1.5rem;
  }
}

.mat-paginator-page-size {
  display: none !important;
}

.mat-ripple-element {
  background: transparent !important;
}

.mat-tooltip {
  text-transform: capitalize;
}

.mat-row.inactive {
  opacity: 0.5;
}

.mat-expansion-panel-content {
  font: unset !important;
}

.mat-checkbox-layout .mat-checkbox-label {
  padding-left: 0 !important;
}

.emoji-mart-search {
  @extend .row;
  align-items: center;

  input {
    font-size: 1rem;
    background-color: transparent !important;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $primary;
  }

  .emoji-mart-search-icon {
    position: relative !important;
    padding: 0;
    top: auto;
    right: auto;
    fill: $primary;
  }
}

.emoji-mart {
  border: none;
  box-shadow: $box-shadow;
  font-size: 1rem;
  background-color: $white;
}

.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  color: $primary !important;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  background-color: $primary !important;
}

.emoji-mart-bar {
  border-color: $primary;
}

.mat-menu-panel {
  max-width: fit-content !important;
}

.mat-menu-panel.emoji .mat-menu-content {
  padding: 0;
}

.x-spreadsheet {
  * {
    box-sizing: content-box;
  }

  .x-spreadsheet-toolbar {
    width: 100% !important;

    .x-spreadsheet-toolbar-btn {
      //print, paint format, filter
      &:nth-child(3),
      &:nth-child(4),
      &:nth-last-child(3) {
        display: none;
      }
    }
  }

  .x-spreadsheet-scrollbar.vertical {
    height: 100% !important;
    bottom: 0;
  }

  .x-spreadsheet-scrollbar.horizontal {
    right: 0;
    width: 100% !important;
    height: 8px;
  }

  .x-spreadsheet-bottombar {
    display: none;
  }

  .x-spreadsheet-editor .x-spreadsheet-editor-area {
    textarea {
      background-color: #e6edf9;
    }
  }
}

.material-icons {
  user-select: none;
}
