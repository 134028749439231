$chatflow-item-width: 300px;
$chatflow-item-height: 88px;

.chatflow {
  @extend .row;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  position: relative;

  @at-root #{&}__menu {
    width: 30rem;
    flex-shrink: 0;
    height: 100%;
    background-color: $white;

    @at-root #{&}__item {
      @extend .row;
      padding: 1rem;
      box-sizing: $box-shadow;
      cursor: pointer;
    }
  }

  @at-root #{&}__editor {
    flex-grow: 1;
    height: 100%;
    width: 100%;

    @at-root #{&}__panel {
      min-width: 30rem;
    }
    @at-root #{&}__canvas {
      width: 100%;
      height: 100%;
      overflow: scroll;
    }
  }
}

.joint-paper.joint-theme-default {
  background-color: $primary-light;
}

feDropShadow {
  flood-color: #e2e2e2;
  flood-opacity: 0.5;
}

.chatflow__step {
  @at-root #{&}__wrapper {
    width: $chatflow-item-width !important;
    height: $chatflow-item-height !important;
    box-shadow: $box-shadow-dark;
  }

  @at-root #{&}__content {
    @extend .row;
    width: $chatflow-item-width !important;
    height: $chatflow-item-height !important;
    position: absolute;
    top: 0;
    left: 0;

    .icon {
      width: $chatflow-item-height;
      height: 100%;
    }

    .info {
      @extend .column;
      flex-grow: 1;
    }
  }

  @at-root #{&}__title {
    fill: $primary;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: capitalize;
  }

  @at-root #{&}__description {
    fill: $gray-dark;
    font-weight: normal;
    letter-spacing: 1px;
  }
}
